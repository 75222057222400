<template>
  <div class="d-flex align-items-center justify-content-center p-5">
    <div class="row">
      <div class="col mb-2 d-flex align-items-center justify-content-center">
        <b-img-lazy
          :src="require('@/assets/images/logo/trackAndTrace.png')"
          class="mr-2"
          style="width: 260px"
        />
      </div>
      <div class="col">
        <div class="text-md-left text-center">
          <h1 class="text-primary font-weight-bolder">
            {{ $t('src.views.pages.inside.trackandtrace.trackT') }}
          </h1>
        </div>
        <h6 class="text-primary font-weight-bolder">
          {{ $t('src.views.pages.inside.trackandtrace.orderI') }}
        </h6>
        <validation-observer ref="trackOrder">
          <validation-provider
            #default="{ errors }"
            name="Order ID"
            rules="required"
          >
            <b-form-input
              id="order-id"
              v-model="orderId"
              :state="errors.length > 0 ? false:null"
              name="orderId"
              :placeholder="$t('src.views.pages.inside.trackandtrace.enterOI')"
            />
            <small class="text-danger">{{ errors[0] ? errors[0] : orderNotFound }}</small>
          </validation-provider>
          <div class="my-2">
            <vue-recaptcha
              ref="recaptcha"
              sitekey="6LfoCf8fAAAAAAYiVz85sf1jN8Uk6fJKnRXeZhW9"
              @verify="verify"
            />
            <div class="mb-2">
              <small class="text-danger ">{{ captchaMessage }}</small>
            </div>
          </div>
          <b-button
            type="button"
            variant="primary"
            block
            :disabled="isLoading"
            @click.prevent="submit"
          >
            <b-spinner
              v-if="isLoading"
              small
              class="float-left"
              :label="$t('src.views.pages.inside.trackandtrace.floatedR')"
            />{{ $t('src.views.pages.inside.trackandtrace.submit') }}</b-button>
        </validation-observer>
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { VueRecaptcha } from 'vue-recaptcha'
import { mapGetters } from 'vuex'
import { required } from '@validations'

export default {
  components: {
    VueRecaptcha,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      verifiedCaptcha: null,
      captchaMessage: '',
      orderId: null,
      orderNotFound: '',
      required,
    }
  },
  computed: {
    ...mapGetters({
      order: 'orders/order',
      isLoading: 'orders/isLoading',
    }),
  },
  methods: {
    async submit() {
      this.$refs.trackOrder.validate().then(async success => {
        if (success) {
          if (this.verifiedCaptcha === null) {
            this.captchaMessage = 'Please verify that you are a Human.'
            return
          }
          const response = await this.$store.dispatch('orders/fetchOrder', { orderId: this.orderId })
          if (response.status === 200) {
            this.$router.push(`/order-tracking/${this.orderId}`)
            this.captchaMessage = ''
            this.orderNotFound = ''
            return
          }
          this.orderNotFound = 'Invalid Order ID.'
        }
      })
    },
    verify(response) {
      this.verifiedCaptcha = response
    },
  },
}
</script>
